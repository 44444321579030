import { Box, HStack, Text, useMultiStyleConfig } from '@chakra-ui/react';

import type { BoxProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren, ReactNode } from 'react';

type Props = BoxProps & {
  title?: string;
  buttons?: ReactNode | ReactNode[];
};

export const PageHeader: FC<PropsWithChildren<Props>> = ({ title, buttons, children, ...props }) => {
  const styles = useMultiStyleConfig('PageHeader');

  return (
    <Box mb="5" {...props}>
      <HStack spacing="2" mb="4" minH="10" alignItems="flex-start">
        <Text
          color={styles?.title?.color as string}
          fontSize="2xl"
          fontWeight="semibold"
          flexGrow="1"
          alignSelf="center"
          lineHeight="normal"
          mr="4"
        >
          {title}
        </Text>
        {buttons}
      </HStack>
      <Box color={styles?.body?.color as string}>
        {typeof children === 'string' ? <Text>{children}</Text> : children}
      </Box>
    </Box>
  );
};
