import { IconButton, Box } from '@chakra-ui/react';

import { PauseIcon, PlayIcon } from '../Icons';

import { VideoPlayTooltip } from './VideoPlayTooltip';

import type { PlayerUiSize } from './types';
import type { FC, RefObject } from 'react';

type Props = {
  isDisabled: boolean;
  isPlaying: boolean;
  setIsPlaying: (flag: boolean) => void;
  wrapperRef: RefObject<HTMLDivElement>;
  size?: PlayerUiSize;
  isFullscreen: boolean;
};

const VideoPlayButton: FC<Props> = ({ isDisabled, isPlaying, setIsPlaying, wrapperRef, size, isFullscreen }) => {
  return (
    <Box>
      <VideoPlayTooltip
        placement="top-end"
        boundaryRef={wrapperRef.current}
        label={isPlaying ? 'Pause' : 'Play'}
        isDisabled={isDisabled}
        portalProps={isFullscreen && wrapperRef ? { containerRef: wrapperRef } : undefined}
      >
        {isPlaying ? (
          <IconButton
            aria-label="Pause"
            variant="ghost"
            icon={<PauseIcon />}
            disabled={isDisabled}
            size={size}
            onClick={() => {
              setIsPlaying(false);
            }}
          />
        ) : (
          <IconButton
            aria-label="Play"
            variant="ghost"
            icon={<PlayIcon />}
            disabled={isDisabled}
            size={size}
            onClick={() => {
              setIsPlaying(true);
            }}
          />
        )}
      </VideoPlayTooltip>
    </Box>
  );
};

export { VideoPlayButton };
