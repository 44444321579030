import { Divider, Flex, Text } from '@chakra-ui/react';

import type { FlexProps, TextProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

export type DividerTextProps = PropsWithChildren<
  FlexProps & {
    textWhiteSpace?: Pick<TextProps, 'whiteSpace'>['whiteSpace'];
    dividerTextColor?: Pick<TextProps, 'color'>['color'];
  }
>;
export const DividerText: FC<DividerTextProps> = ({
  children,
  textWhiteSpace = 'nowrap',
  dividerTextColor,
  ...props
}) => {
  return (
    <Flex alignItems="center" w="full" {...props}>
      <Divider />
      <Text px="6" whiteSpace={textWhiteSpace} color={dividerTextColor}>
        {children}
      </Text>
      <Divider />
    </Flex>
  );
};
