import { IconButton } from '@chakra-ui/react';
import { useState, useEffect, useCallback } from 'react';
import { fullscreen } from 'shared-utils';

import { ExpandIcon, ShrinkIcon } from '../Icons';

import { VideoPlayTooltip } from './VideoPlayTooltip';

import type { PlayerUiSize } from './types';
import type { PlacementWithLogical } from '@chakra-ui/react';
import type { RefObject } from 'react';

type Props = {
  isDisabled: boolean;
  onFullscreenChange: (isFullscreen: boolean) => void;
  wrapperRef: RefObject<HTMLDivElement>;
  size: PlayerUiSize;
};

const PLACEMENT: PlacementWithLogical = 'top-end';

const VideoFullscreenButton = ({ isDisabled, onFullscreenChange, size, wrapperRef }: Props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const fullScreenListener = useCallback(() => {
    if (typeof document !== 'undefined') {
      const isFS = !!document.fullscreenElement;
      setIsFullscreen(isFS);
      onFullscreenChange(isFS);
    }
  }, [setIsFullscreen, onFullscreenChange]);
  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef.current.addEventListener('fullscreenchange', fullScreenListener);
    }
  }, [wrapperRef, fullScreenListener]);

  if (isFullscreen) {
    return (
      <VideoPlayTooltip
        boundaryRef={wrapperRef.current}
        placement={PLACEMENT}
        label="Exit fullscreen (Esc)"
        portalProps={{ containerRef: wrapperRef }}
      >
        <IconButton
          aria-label="Shrink"
          variant="ghost"
          icon={<ShrinkIcon />}
          size={size}
          onClick={() => {
            fullscreen.close();
          }}
        />
      </VideoPlayTooltip>
    );
  }
  return (
    <VideoPlayTooltip boundaryRef={wrapperRef.current} placement={PLACEMENT} label="Fullscreen" isDisabled={isDisabled}>
      <IconButton
        aria-label="Expand"
        variant="ghost"
        icon={<ExpandIcon />}
        disabled={isDisabled}
        size={size}
        onClick={() => {
          fullscreen.open(wrapperRef.current);
        }}
      />
    </VideoPlayTooltip>
  );
};

export { VideoFullscreenButton };
