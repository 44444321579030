import { Box, Circle } from '@chakra-ui/react';

import { InvisibleSlider } from '../InvisibleSlider/InvisibleSlider';

import type { PlayerUiSize } from './types';
import type { FC } from 'react';

type Props = {
  isDisabled: boolean;
  loaded: number;
  progress: number;
  onChange: (value: number) => void;
  stepSize?: number;
  size?: PlayerUiSize;
};

const BORDER_RADIUS = 'md';

const VideoProgressBar: FC<Props> = ({ isDisabled, loaded, progress, onChange, stepSize, size }) => {
  return (
    <Box
      pos="relative"
      h="1.5"
      backgroundColor="whiteAlpha.300"
      mx={size === 'sm' ? '2' : '3'}
      borderRadius={BORDER_RADIUS}
    >
      {/* Show how much of the video is loaded */}
      <Box
        h="full"
        pos="absolute"
        style={{ width: `${loaded * 100}%` }}
        backgroundColor="whiteAlpha.500"
        borderRadius={BORDER_RADIUS}
      />
      {/* Show how much of the video has been played */}
      <Box
        h="full"
        pos="absolute"
        style={{ width: `${progress * 100}%` }}
        backgroundColor="gray.100"
        borderRadius={BORDER_RADIUS}
      />
      {/* Represent the draggable slider handle */}
      <Circle
        size="3"
        pos="absolute"
        top="50%"
        backgroundColor="black"
        transform="translate(-50%, -50%)"
        style={{ left: `${progress * 100}%` }}
        boxShadow="0 0 0 var(--chakra-sizes-1) var(--chakra-colors-white) inset"
      />
      {/* Users actually interact with the invisible range element over the top of the visible progress elements */}
      {!isDisabled && (
        <InvisibleSlider
          onChange={({ target }) => {
            onChange(Number(target.value));
          }}
          min={0}
          max={1}
          step={stepSize ?? 0.002}
          style={{
            position: 'absolute',
            width: '100%',
            height: '200%',
            left: 0,
            top: '-50%',
            cursor: 'pointer',
          }}
        />
      )}
    </Box>
  );
};

export { VideoProgressBar };
