import ReactSelectElement from 'react-select/async';

import { useSelectStyles } from './ReactSelect';

import type { GroupBase } from 'react-select';
import type { AsyncProps } from 'react-select/async';

export function ReactSelectAsync<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: AsyncProps<Option, IsMulti, Group>) {
  const styleConfig = useSelectStyles<Option, IsMulti, Group>();
  return <ReactSelectElement {...props} styles={styleConfig} />;
}
