export const getDurationFromMs = (
  ms: number,
  hide: {
    hour?: boolean;
    min?: boolean;
    second?: boolean;
  } = {},
): {
  hour?: number;
  min?: number;
  second?: number;
} => {
  const second = Math.floor(ms / 1000);
  const min = Math.floor(second / 60);
  const hour = Math.floor(min / 60);

  return {
    ...(hide.hour ? {} : { hour: hour % 24 }),
    ...(hide.min ? {} : { min: min % 60 }),
    ...(hide.second ? {} : { second: second % 60 }),
  };
};
