// Just the mute/unmute button

import { IconButton } from '@chakra-ui/react';
import { useMemo } from 'react';

import { VolumeFilledHighIcon, VolumeFilledLowIcon, VolumeFilledMuteIcon } from '../../Icons';
import { VideoPlayTooltip } from '../VideoPlayTooltip';

import type { PlayerUiSize } from '../types';
import type { RefObject } from 'react';

type Props = {
  isDisabled: boolean;
  volume: number;
  onClick: () => void;
  size?: PlayerUiSize;
  wrapperRef: RefObject<HTMLDivElement>;
  isFullscreen: boolean;
};

const VideoVolumeButton = ({ isDisabled, volume, onClick, size, wrapperRef, isFullscreen }: Props) => {
  // Render a different button icon based on volume level
  const volumeButtonIcon = useMemo(() => {
    if (volume === 0) {
      return <VolumeFilledMuteIcon />;
    }
    if (volume < 0.25) {
      return <VolumeFilledLowIcon />;
    }
    return <VolumeFilledHighIcon />;
  }, [volume]);

  return (
    <VideoPlayTooltip
      boundaryRef={wrapperRef.current}
      placement="top"
      label={volume > 0 ? 'Mute' : 'Unmute'}
      isDisabled={isDisabled}
      portalProps={isFullscreen ? { containerRef: wrapperRef } : undefined}
    >
      <IconButton
        aria-label="Volume"
        variant="ghost"
        icon={volumeButtonIcon}
        disabled={isDisabled}
        size={size}
        onClick={onClick}
      />
    </VideoPlayTooltip>
  );
};

export { VideoVolumeButton };
