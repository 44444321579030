import { Box, HStack, Input, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { HexColorPicker } from 'react-colorful';

import type { BoxProps } from '@chakra-ui/react';
import type { ChangeEventHandler, FC } from 'react';

type Props = Omit<BoxProps, 'onChange' | 'color'> & {
  color: string;
  onChange: (newColor: string) => void;
  pickerHeight?: BoxProps['h'];
  presetColors?: string[] | null;
};

const PRESET_COLORS = [
  '#181919',
  '#001d3d',
  '#284b63',
  '#577590',
  '#c0d6df',
  '#606acb',
  '#3e3bbb',
  '#553b6e',
  '#70b2C4',
  '#347286',
  '#197278',
  '#6Ab586',
  '#40916c',
  '#ebcb62',
  '#e59d59',
  '#cd7435',
  '#fec3a9',
  '#da615c',
  '#b53831',
  '#800f2f',
];

export const Overrides = () => {
  return (
    <Global
      styles={`
        .color-picker .react-colorful {
          width: 100%;
          height: 200px
        }

        .color-picker .react-colorful__saturation {
          border-radius: 0.375rem 0.375rem 0 0;
        }

        .color-picker .react-colorful__saturation-pointer {
          width: 20px;
          height: 20px;
          background: transparent;
          border: 1px solid #fff;
        }

        .color-picker .react-colorful__hue {
          height: 10px;
          margin-top: 20px;
          border-radius: 10px;
          margin: 16px;
        }
        
        .color-picker .react-colorful__hue-pointer {
          width: 12px;
          height: 12px;
          border-radius: 10px;
        }
      `}
    />
  );
};

type ColorPickerInputProps = {
  color: string;
  onChange: (newColor: string) => void;
};

export const ColorPickerInput: FC<ColorPickerInputProps> = ({ color, onChange }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = evt => {
    onChange(evt.target.value);
  };

  return <Input value={color} onChange={handleChange} spellCheck="false" />;
};

export const ColorPicker: FC<Props> = ({
  onChange,
  color,
  presetColors = PRESET_COLORS,
  pickerHeight = '52',
  ...props
}) => {
  const handleHexClick = (c: string) => () => {
    onChange(c);
  };

  return (
    <Box className="color-picker" w="full" {...props}>
      <Overrides />
      <Box h={pickerHeight}>
        <HexColorPicker color={color} onChange={onChange} style={{ height: '100%' }} />
      </Box>
      <Box px="4" pb="4" w="full">
        <HStack>
          <Text>Hex</Text>
          <ColorPickerInput color={color} onChange={onChange} />
        </HStack>
        {presetColors && (
          <Wrap mt="4" justifyContent="space-between" w="full" spacing={3}>
            {presetColors.map(c => {
              return <WrapItem w="4" cursor="pointer" onClick={handleHexClick(c)} key={c} h="4" rounded="sm" bg={c} />;
            })}
          </Wrap>
        )}
      </Box>
    </Box>
  );
};
