import { Tooltip } from '@chakra-ui/react';

import type { TooltipProps } from '@chakra-ui/react';

// PortalProps containerRef is required for fullscreen view
export const VideoPlayTooltip = ({
  modifiers,
  boundaryRef,
  ...rest
}: TooltipProps & { boundaryRef?: HTMLElement | null }) => {
  const preventOverflowModifier = (() => {
    const mod = modifiers?.find(modifier => modifier.name === 'preventOverflow');
    if (mod) {
      return [mod];
    }

    if (boundaryRef) {
      return [
        {
          name: 'preventOverflow',
          options: {
            // Tooltip positioned weird when Tooltip is too closed to the edge of the screen
            // and there is preventOverflow modifier which checks if Tooltip is too closed to the edge of the screen
            // and automatically add offset which we don't want it
            // boundary sets new boundary element so it calculates against correct element
            // also when tooltip text overflows, it does prevent overflow (this is why boundary is better than mainAxis)
            boundary: boundaryRef,
          },
        },
      ];
    }
    return [];
  })();

  return <Tooltip modifiers={[...preventOverflowModifier, ...(modifiers || [])]} {...rest} />;
};
