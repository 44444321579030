/* eslint-disable max-lines */
import { Box, DarkMode, Flex, Image, Spinner, Text, useBoolean, useDimensions } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { formatSToTime } from 'shared-utils';

import { VideoAlert } from './VideoAlert';
import { VideoFullscreenButton } from './VideoFullscreenButton';
import { VideoPlayButton } from './VideoPlayButton';
import { VideoPlaybackRate } from './VideoPlaybackRate';
import { VideoPlayerMedia } from './VideoPlayerMedia';
import { VideoPoster } from './VideoPoster';
import { VideoProgressBar } from './VideoProgressBar';
import { VideoVolumeControl } from './VideoVolumeControl';

import type { PlayerUiSize } from './types';
import type { BoxProps, LayoutProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';
import type ReactPlayer from 'react-player';
import type { Config, ReactPlayerProps } from 'react-player';
import type BaseReactPlayer from 'react-player';

type Props = Pick<ReactPlayerProps, 'onEnded'> & {
  url?: string | null;
  maxWidth?: LayoutProps['maxWidth']; // container max width
  maxHeight?: LayoutProps['maxHeight']; // container max height
  loading?: boolean;
  pictureInPicture?: boolean | BoxProps;
  seekTo?: number | null; // seconds
  onProgress?: (progress: { seconds: number; percent: number }) => void;
  playing?: boolean;
  config?: Config;
  onChangePlaying?: (playing: boolean) => void;
  onPlayingChange?: () => void;
  onVideoReady?: (player: ReactPlayer) => void;
  fade?: number | { video?: number; audio?: number } | null; // ms
  posterImage?: string; // Poster image to render instead of the video player until the user clicks
  placeholder?: ReactNode | ReactNode[]; // Placeholder (eg processing state) which will render instead of the video player as long as it's present in props
};
const VideoPlayer = ({
  url,
  maxWidth,
  maxHeight,
  loading = false,
  onPlayingChange,
  pictureInPicture,
  seekTo,
  onEnded,
  onProgress,
  playing,
  onChangePlaying,
  fade,
  posterImage,
  placeholder,
  onVideoReady,
  config,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const controlBarRef = useRef<HTMLDivElement>(null);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const videoPlayer = videoPlayerRef?.current as undefined | BaseReactPlayer;

  // Show the clickable poster image component
  const [showPoster, setShowPoster] = useBoolean(!!posterImage);
  // After the poster is clicked and removed, show an image in the video player while it's starting up
  const [showPosterTransition, setShowPosterTransition] = useBoolean(showPoster);
  const [videoIsPlaying, setVideoPlayerPlaying] = useState(!!playing);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [videoVolume, setVideoVolume] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [videoBuffering, setVideoBuffering] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(0);
  const [controlsBarHovered, setControlsBarHovered] = useState(false);
  const [interactedWithVideo, setInteractedWithVideo] = useState(false);
  const onVideoProgressChange = (progress: number) => {
    videoPlayer?.seekTo(progress);
  };

  const videoPlayerPlaying = playing ?? videoIsPlaying;
  const controlsDisabled = loading || videoDuration === 0 || videoLoaded === 0;
  const controlsMousemoveTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const pictureInPictureSet = !!pictureInPicture;
  const pictureInPictureTransitionTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [playerError, setPlayerError] = useState<any | null>(null);

  // Controlled player usage
  useEffect(() => {
    if (seekTo && videoPlayer?.seekTo) {
      setInteractedWithVideo(true);
      videoPlayer?.seekTo(seekTo, 'seconds');
    }
  }, [seekTo, videoPlayer]);
  useEffect(() => {
    if (onChangePlaying) {
      onChangePlaying(videoPlayerPlaying);
    }
  }, [videoPlayerPlaying, onChangePlaying]);

  // show the controls if:
  // - the video is paused
  // - the mouse is over the controls bar
  // - if the user has recently interacted with the video
  const showControlsBar = useMemo(() => {
    return !videoPlayerPlaying || controlsBarHovered || interactedWithVideo;
  }, [videoPlayerPlaying, controlsBarHovered, interactedWithVideo]);

  // Hovering / moving the mouse over the player should show the controls temporarily
  useEffect(() => {
    if (interactedWithVideo) {
      if (controlsMousemoveTimeout.current) {
        clearTimeout(controlsMousemoveTimeout.current);
      }
      controlsMousemoveTimeout.current = setTimeout(() => {
        setInteractedWithVideo(false);
      }, 2500);
    }
  }, [interactedWithVideo]);

  // Playing or pausing the video should show the controls
  useEffect(() => {
    if (videoPlayerPlaying) {
      setInteractedWithVideo(true);
    }
  }, [videoPlayerPlaying]);

  // Determine the player UI sizes & options based on the container size
  const playerDimensions = useDimensions(wrapperRef, true);
  const playerWidth = useMemo(() => {
    if (isFullscreen && typeof window !== 'undefined') {
      return window.innerWidth;
    }
    if (!playerDimensions?.contentBox?.width) {
      return undefined;
    }
    return Math.round((playerDimensions.contentBox.width ?? 0) / 10) * 10;
  }, [isFullscreen, playerDimensions]);

  useEffect(() => {
    // Add some slightly deferred subtle transitions to hide while the controls are resizing
    if (wrapperRef?.current?.style) {
      wrapperRef.current.style.visibility = 'hidden';
      wrapperRef.current.style.opacity = '0';
      if (pictureInPictureSet) {
        wrapperRef.current.style.transform = 'scale(0.95)';
      }
    }
    if (typeof window !== 'undefined') {
      // Fire a window resize so that useDimensions will recalculate the controls size
      window.dispatchEvent(new Event('resize'));
      if (pictureInPictureTransitionTimeout.current) {
        clearTimeout(pictureInPictureTransitionTimeout.current);
      }
      pictureInPictureTransitionTimeout.current = setTimeout(() => {
        if (wrapperRef?.current?.style) {
          wrapperRef.current.style.visibility = 'visible';
          wrapperRef.current.style.opacity = '1';
          wrapperRef.current.style.transform = 'scale(1)';
        }
      }, 150);
    }
    return () => {
      if (pictureInPictureTransitionTimeout.current) {
        clearTimeout(pictureInPictureTransitionTimeout.current);
      }
    };
  }, [pictureInPictureSet]);

  const responsiveOptions = useMemo(() => {
    const options: {
      ui: PlayerUiSize;
      alignProgress: 'center' | 'right';
      showRate: boolean;
      showVolume: boolean;
      showDuration: boolean;
      showProgress: boolean;
      showProgressBar: boolean;
      showPlayer: boolean;
    } = {
      // Defaults
      ui: 'md',
      alignProgress: 'center',
      showRate: true,
      showVolume: true,
      showDuration: true,
      showProgress: true,
      showProgressBar: true,
      showPlayer: true,
    };
    if (!playerWidth) {
      return options;
    }
    if (playerWidth <= 550) {
      options.alignProgress = 'right';
    }
    if (playerWidth <= 430) {
      options.ui = 'sm';
    }
    if (playerWidth <= 430) {
      options.ui = 'sm';
    }
    if (playerWidth <= 300) {
      options.showDuration = false;
    }
    if (playerWidth <= 240) {
      options.showRate = false;
      options.showVolume = false;
    }
    if (playerWidth <= 150) {
      options.showProgressBar = false;
    }
    if (playerWidth <= 80) {
      options.showPlayer = false;
    }
    return options;
  }, [playerWidth]);

  const pictureInPictureProps: BoxProps = useMemo(() => {
    if (!pictureInPicture || isFullscreen || playerError) {
      return {};
    }
    const defaultProps = {
      width: '320px',
      height: '180px',
      maxW: '100vw',
      pos: 'fixed',
      zIndex: 'modal',
      top: undefined,
      left: '0',
      right: undefined,
      bottom: '0',
      padding: '2',
    };

    if (typeof pictureInPicture === 'object') {
      return {
        ...defaultProps,
        ...pictureInPicture,
      };
    }
    return defaultProps;
  }, [pictureInPicture, isFullscreen, playerError]);

  const { audioFadeMultiplier, videoFadeMultiplier } = useMemo(() => {
    const fadeMultiplier = { audioFadeMultiplier: 1, videoFadeMultiplier: 1 };
    if (!fade) {
      return fadeMultiplier;
    }
    const fadeTiming = typeof fade === 'number' ? { audio: fade, video: fade } : fade;

    const progressMs = videoProgress * videoDuration * 1000;
    const msFromEnd = videoDuration * 1000 - progressMs;
    if (fadeTiming.audio && progressMs < fadeTiming.audio) {
      fadeMultiplier.audioFadeMultiplier = progressMs / fadeTiming.audio;
    }
    if (fadeTiming.video && progressMs < fadeTiming.video) {
      fadeMultiplier.videoFadeMultiplier = progressMs / fadeTiming.video;
    }
    if (fadeTiming.audio && msFromEnd < fadeTiming.audio) {
      fadeMultiplier.audioFadeMultiplier = msFromEnd / fadeTiming.audio;
    }
    if (fadeTiming.video && msFromEnd < fadeTiming.video) {
      fadeMultiplier.videoFadeMultiplier = msFromEnd / fadeTiming.video;
    }
    return fadeMultiplier;
  }, [fade, videoProgress, videoDuration]);

  const { volumeWithFade, fadeOpacity } = useMemo(
    () => ({ volumeWithFade: videoVolume * audioFadeMultiplier, fadeOpacity: videoFadeMultiplier }),
    [audioFadeMultiplier, videoFadeMultiplier, videoVolume],
  );

  const borderRadius = isFullscreen ? undefined : 'md';

  if (!responsiveOptions.showPlayer) {
    return <Box width="full" height="full" borderRadius={borderRadius} bgColor="black" />;
  }

  if (placeholder) {
    return (
      <Box
        left="0"
        top="0"
        w="full"
        h="full"
        position="relative"
        margin="0"
        padding="0"
        borderRadius={borderRadius}
        overflow="hidden"
      >
        {(() => {
          if (React.isValidElement(placeholder)) {
            return placeholder;
          }
          if (!Array.isArray(placeholder)) {
            return null;
          }
          if (responsiveOptions.ui === 'md' && placeholder[1]) {
            return placeholder[1];
          }
          return placeholder[0];
        })()}
      </Box>
    );
  }

  return (
    <Box
      left="0"
      top="0"
      w="full"
      h="full"
      position="relative"
      margin="0"
      padding="0"
      background="blackAlpha.600" // just visible when PiP is enabled
      borderRadius={borderRadius}
    >
      {/* PiP fixed position wrapper */}
      <Box left="0" top="0" w="full" h="full" {...pictureInPictureProps} boxSizing="content-box">
        <Box
          bgColor="black"
          // Override the style set by AspectRatio - some controls will need to overflow.
          overflow="visible !important"
          borderRadius={borderRadius}
          width="full"
          height="full"
          maxWidth={isFullscreen ? undefined : maxWidth}
          maxHeight={isFullscreen ? undefined : maxHeight}
          // position={isFullscreen ? 'fixed' : 'relative'}
          ref={wrapperRef}
          className="wrapper"
          position="relative"
          margin="0"
          padding="0"
          onMouseMove={() => {
            if (showPoster) {
              return;
            }

            setInteractedWithVideo(true);
          }}
          transition="0.1s opacity, 0.2s transform"
        >
          {(() => {
            if (playerError) {
              return (
                <VideoAlert
                  title="Playback error"
                  description="This video cannot be played - please contact support"
                  size={responsiveOptions.ui}
                />
              );
            }

            return (
              <>
                {showPoster && posterImage ? (
                  <VideoPoster
                    src={posterImage ?? ''}
                    onClick={() => {
                      setShowPoster.off();

                      if (onPlayingChange) {
                        onPlayingChange();
                      } else {
                        setVideoPlayerPlaying(true);
                      }
                    }}
                    // iconSize={responsiveOptions.ui} // ~TODO: responsive sizes (need an additional dimensions ref listener, not worth it)
                  />
                ) : null}
                {/* Player */}
                {/* We need to mount ReactPlayer initially because there is a bug caused by introducing next14\
                    that it causes uncontrollable audio when ReactPlayer mounts with valid video URL and playing true */}
                <Box
                  pos="absolute"
                  left="0"
                  top="0"
                  w="full"
                  h="full"
                  overflow="hidden"
                  borderRadius={borderRadius}
                  opacity={fadeOpacity}
                  // Background for the controls (needs to be cropped by player's border radius)
                  _after={{
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    height: '6rem',
                    opacity: showControlsBar ? 1 : 0,
                    transition: 'opacity 0.2s',
                    background: 'linear-gradient(0, rgba(0,0,0,0.8) 0, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);',
                  }}
                >
                  <VideoPlayerMedia
                    config={config}
                    url={url ?? undefined}
                    playing={videoPlayerPlaying}
                    playbackRate={playbackRate}
                    volume={volumeWithFade}
                    onEnded={onEnded}
                    playerRef={videoPlayerRef}
                    onReady={onVideoReady}
                    onDuration={durationSeconds => {
                      setVideoDuration(durationSeconds);
                      if (showPosterTransition) {
                        setShowPosterTransition.off();
                      }
                    }}
                    onProgress={({ played, loaded, playedSeconds }) => {
                      // Update the local UI states
                      setVideoProgress(played);
                      setVideoLoaded(loaded);
                      if (onProgress) {
                        onProgress({ seconds: playedSeconds, percent: played });
                      }
                    }}
                    onBufferingChange={buffering => {
                      setVideoBuffering(buffering);
                    }}
                    onPlayerError={error => {
                      setPlayerError(error);
                    }}
                  />
                </Box>
                {/* Poster image (if there is one) to render be */}
                {showPosterTransition && (
                  <Image
                    src={posterImage}
                    objectFit="contain"
                    width="100%"
                    height="100%"
                    pos="absolute"
                    zIndex="0"
                    alt="Click to play"
                  />
                )}
                {/* Clickable overlay */}
                <Box
                  pos="absolute"
                  left="0"
                  top="0"
                  w="full"
                  h="full"
                  cursor={showControlsBar ? 'pointer' : 'none'}
                  role="button"
                  onClick={() => {
                    if (onPlayingChange) {
                      onPlayingChange();
                    } else {
                      setVideoPlayerPlaying(s => !s);
                    }
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {(loading || videoBuffering) && <Spinner size={responsiveOptions.ui === 'sm' ? 'lg' : 'xl'} />}
                </Box>
                {/* Controls bar */}
                <Box
                  pos="absolute"
                  bottom="0"
                  left="0"
                  w="full"
                  maxH="100%"
                  pt={responsiveOptions.showProgressBar ? '6' : '0'}
                  transform="translateY(0)"
                  opacity="1"
                  transitionProperty="transform, opacity"
                  transitionDuration="0.2s"
                  style={showControlsBar ? undefined : { transform: 'translateY(10%)', opacity: 0 }}
                  onMouseEnter={() => {
                    setControlsBarHovered(true);
                  }}
                  onMouseLeave={() => {
                    setControlsBarHovered(false);
                  }}
                >
                  <DarkMode>
                    {responsiveOptions.showProgressBar && (
                      <VideoProgressBar
                        isDisabled={controlsDisabled}
                        progress={videoProgress}
                        loaded={videoLoaded}
                        onChange={onVideoProgressChange}
                        size={responsiveOptions.ui}
                      />
                    )}
                    <Flex flexDirection="row" width="full" ref={controlBarRef}>
                      <Flex
                        flexBasis={responsiveOptions.alignProgress === 'center' ? '30%' : 0}
                        flexShrink="1"
                        flexDirection="row"
                        justifyContent="flex-start"
                        p="1"
                      >
                        <VideoPlayButton
                          isFullscreen={isFullscreen}
                          isPlaying={videoPlayerPlaying}
                          isDisabled={controlsDisabled}
                          setIsPlaying={isPlaying => {
                            if (onPlayingChange) {
                              onPlayingChange();
                            } else {
                              setVideoPlayerPlaying(isPlaying);
                            }
                          }}
                          size={responsiveOptions.ui}
                          wrapperRef={wrapperRef}
                        />
                        {responsiveOptions.showRate && (
                          <VideoPlaybackRate
                            isFullscreen={isFullscreen}
                            isDisabled={controlsDisabled}
                            playbackRate={playbackRate}
                            setPlaybackRate={rate => {
                              setPlaybackRate(rate);
                            }}
                            size={responsiveOptions.ui}
                            wrapperRef={wrapperRef}
                          />
                        )}
                        {responsiveOptions.showVolume && (
                          <VideoVolumeControl
                            isFullscreen={isFullscreen}
                            isDisabled={controlsDisabled}
                            defaultVolume={videoVolume}
                            setVolume={volume => {
                              setVideoVolume(volume);
                            }}
                            size={responsiveOptions.ui}
                            wrapperRef={wrapperRef}
                          />
                        )}
                      </Flex>
                      <Flex
                        flexBasis={responsiveOptions.alignProgress === 'center' ? '40%' : 0}
                        ml={responsiveOptions.alignProgress === 'right' ? 'auto' : undefined}
                        alignItems={responsiveOptions.alignProgress === 'right' ? 'flex-end' : 'center'}
                        flexDirection="column"
                        flexGrow="1"
                        px="2"
                        flexShrink="1"
                        userSelect="none"
                        my="auto"
                        overflow="hidden"
                        textOverflow="ellipsis"
                      >
                        {videoDuration && responsiveOptions.showProgress ? (
                          <Text
                            as="span"
                            fontSize={responsiveOptions.ui === 'sm' ? 'xs' : 'sm'}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            maxW="full"
                            textOverflow="ellipsis"
                            style={{ fontVariant: 'tabular-nums' }}
                            color="whiteAlpha.700"
                          >
                            <Text as="span" color="white" align="right">
                              {formatSToTime(videoProgress * videoDuration)}
                            </Text>
                            <Text as="span" mx="2" display={responsiveOptions.showDuration ? undefined : 'none'}>
                              /
                            </Text>
                            <Text as="span" display={responsiveOptions.showDuration ? undefined : 'none'}>
                              {formatSToTime(videoDuration)}
                            </Text>
                          </Text>
                        ) : null}
                      </Flex>
                      <Flex
                        flexBasis={responsiveOptions.alignProgress === 'center' ? '30%' : 0}
                        flexShrink="1"
                        flexDirection="row"
                        justifyContent="flex-end"
                        p="1"
                      >
                        <VideoFullscreenButton
                          isDisabled={controlsDisabled}
                          onFullscreenChange={isFS => {
                            setIsFullscreen(isFS);
                          }}
                          size={responsiveOptions.ui}
                          wrapperRef={wrapperRef}
                        />
                      </Flex>
                    </Flex>
                  </DarkMode>
                </Box>
              </>
            );
          })()}
        </Box>
      </Box>
    </Box>
  );
};

export { VideoPlayer };
