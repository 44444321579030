import { Text } from '@chakra-ui/react';

import type { TextProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

export const Optional: FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => {
  return (
    <Text {...props} color="gray.500">
      {children}
    </Text>
  );
};
