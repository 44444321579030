import { Button, DarkMode, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react';
import { useMemo } from 'react';

import { VideoPlayTooltip } from './VideoPlayTooltip';

import type { PlayerUiSize } from './types';
import type { FC, RefObject } from 'react';

const PLAYBACK_RATE_KEYS = ['double', '1.75', '1.5', '1.25', 'normal'] as const;
type PlaybackRate = (typeof PLAYBACK_RATE_KEYS)[number];
type PlaybackRateValue = {
  label: string;
  menuLabel?: string;
  value: number;
};
const PLAYBACK_RATES: { [key in PlaybackRate]: PlaybackRateValue } = {
  normal: { label: '1x', menuLabel: 'Normal', value: 1 },
  1.25: { label: '1.25x', menuLabel: '1.25', value: 1.25 },
  1.5: { label: '1.5x', menuLabel: '1.5', value: 1.5 },
  1.75: { label: '1.75x', menuLabel: '1.75', value: 1.75 },
  double: { label: '2x', value: 2 },
};

type Props = {
  isDisabled: boolean;
  playbackRate: number;
  setPlaybackRate: (rate: number) => void;
  wrapperRef: RefObject<HTMLDivElement>;
  size?: PlayerUiSize;
  isFullscreen: boolean;
};

export const VideoPlaybackRate: FC<Props> = ({
  isDisabled,
  playbackRate,
  setPlaybackRate,
  size,
  wrapperRef,
  isFullscreen,
}) => {
  const rateDetails = useMemo(() => {
    const matchByValue = Object.values(PLAYBACK_RATES).find(rate => rate.value === playbackRate);
    if (matchByValue) {
      return matchByValue;
    }
    setPlaybackRate(PLAYBACK_RATES.normal.value);
    return PLAYBACK_RATES.normal;
  }, [setPlaybackRate, playbackRate]);
  return (
    <DarkMode>
      <Menu placement="top-start" strategy="fixed">
        <VideoPlayTooltip
          boundaryRef={wrapperRef.current}
          placement="top"
          label="Playback speed"
          isDisabled={isDisabled}
          portalProps={isFullscreen && wrapperRef ? { containerRef: wrapperRef } : undefined}
        >
          <MenuButton
            as={Button}
            variant="ghost"
            aria-label="Set playback speed"
            disabled={isDisabled}
            w={size === 'sm' ? '10' : '16'}
            px="0.5"
            size={size}
          >
            {rateDetails.label}
          </MenuButton>
        </VideoPlayTooltip>
        <MenuList zIndex="popover">
          <MenuOptionGroup
            type="radio"
            defaultValue={'normal' as PlaybackRate}
            onChange={rateKey => {
              setPlaybackRate((PLAYBACK_RATES[rateKey as PlaybackRate] || PLAYBACK_RATES.normal).value);
            }}
          >
            {PLAYBACK_RATE_KEYS.map(key => {
              if (!PLAYBACK_RATES[key]) {
                return null;
              }
              return (
                <MenuItemOption
                  key={key}
                  value={key}
                  fontSize="sm"
                  py="1"
                  px="2"
                  color="var(--chakra-colors-chakra-body-text)"
                  onClick={() => {
                    setPlaybackRate((PLAYBACK_RATES[key] || PLAYBACK_RATES.normal).value);
                  }}
                >
                  {PLAYBACK_RATES[key].menuLabel || PLAYBACK_RATES[key].label}
                </MenuItemOption>
              );
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </DarkMode>
  );
};
