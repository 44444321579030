import {
  AspectRatio,
  Box,
  Button,
  Link,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useBoolean,
  useDimensions,
  useDisclosure,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { PlayIcon } from '../Icons';

import type { BoxProps, ButtonProps } from '@chakra-ui/react';
import type { FC, PropsWithChildren } from 'react';

type Props = BoxProps & {
  id?: string;
  title?: string;
  thumbnailSrc?: string;
  videoEmbedUrl?: string;
  learnMoreLink?: string;
  colorScheme?: 'gray';
};

/*
  - If `id` is not provided, the dismissed state will not persist
    - When this component is dismissed, all other instances with the same `id` will also be dismissed
  - If `thumbnailSrc` is not provided, don't render one
  - If `videoEmbedUrl` is not provided, thumbnail is just a non-interactive image, with no play icon
  - If `learnMoreLink` is not provided, don't render a button for it
*/

const TUTORIAL_DISMISSED_STORAGE_KEY = 'tutorialDismissedStatus';

type DismissedStatus = Record<string, boolean>;

const getDismissedStatus: () => DismissedStatus = () => {
  try {
    const storageValue = JSON.parse(localStorage.getItem(TUTORIAL_DISMISSED_STORAGE_KEY) ?? '{}');
    if (storageValue && typeof storageValue === 'object') {
      return storageValue as DismissedStatus;
    }
    return {};
  } catch {
    return {};
  }
};

export const setTutorialDismissed = (id: string, dismissed: boolean) => {
  localStorage.setItem(
    TUTORIAL_DISMISSED_STORAGE_KEY,
    JSON.stringify({
      ...getDismissedStatus(),
      [id]: dismissed,
    }),
  );
};

export const tutorialIsDismissed: (id?: string) => boolean = id => {
  if (!id) {
    return false;
  }
  return getDismissedStatus()?.[id] ?? false;
};

export const Tutorial: FC<PropsWithChildren<Props>> = ({
  id,
  title,
  thumbnailSrc,
  videoEmbedUrl,
  learnMoreLink,
  children,
  ...props
}) => {
  const [dismissed, setDismissed] = useBoolean(tutorialIsDismissed(id));
  const boxRef = useRef<HTMLDivElement>(null);
  const modal = useDisclosure();

  const dimensions = useDimensions(boxRef, true);
  const width = dimensions?.borderBox?.width;

  const stack = width ? width <= 480 : false; // TODO base on dimensions
  const styles = useMultiStyleConfig('Tutorial');

  useEffect(() => {
    if (id && dismissed) {
      setTutorialDismissed(id, dismissed);
    }
  }, [id, dismissed]);

  if (dismissed) {
    return null;
  }

  return (
    <Stack
      ref={boxRef}
      w="full"
      p="4"
      borderRadius="xl"
      direction={stack ? 'column' : 'row'}
      spacing={stack ? '4' : '7'}
      bgColor={styles?.container?.bgColor as string}
      {...props}
    >
      {thumbnailSrc && (
        <Box
          minH="7.5rem"
          alignSelf="stretch"
          w="auto"
          flex="1 1 0%"
          pos="relative"
          maxW={stack ? undefined : '16rem'}
          borderRadius="md"
          overflow="hidden"
        >
          <Box
            minH="full"
            w="full"
            pt="62.5%"
            pos="relative"
            background="no-repeat 50% 50%"
            backgroundColor="blackAlpha.300"
            backgroundSize="cover"
            backgroundImage={thumbnailSrc}
          />
          {videoEmbedUrl && (
            <Box
              pos="absolute"
              role="button"
              onClick={() => {
                modal.onOpen();
              }}
              left="0"
              top="0"
              h="full"
              w="full"
              cursor="pointer"
              bgColor="rgba(0,0,0,0)"
              transition="background-color 0.2s"
              _hover={{ bgColor: 'rgba(0,0,0,0.3)' }}
              color="white"
            >
              <PlayIcon pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" boxSize="7" />
            </Box>
          )}
        </Box>
      )}
      <Box pos="relative" zIndex="base" flex="2 1 0%" maxW="3xl" mr="auto">
        <Text mb="2" fontSize="lg" fontWeight="semibold" color={styles?.title?.color as string}>
          {title}
        </Text>
        <Box mb="3" color={styles?.body?.color as string}>
          {typeof children === 'string' ? <Text>{children}</Text> : children}
        </Box>
        <Stack direction="row" spacing="2">
          {learnMoreLink && (
            <Button
              size="sm"
              {...(styles?.solidButton as ButtonProps)}
              as={Link}
              href={learnMoreLink}
              target="_blank"
              rel="noreferrer nofollow"
            >
              Learn more
            </Button>
          )}
          <Button
            size="sm"
            variant={learnMoreLink ? 'ghost' : 'solid'}
            {...((learnMoreLink ? styles?.ghostButton : styles?.solidButton) as ButtonProps)}
            onClick={setDismissed.on}
          >
            Dismiss
          </Button>
        </Stack>
      </Box>
      {videoEmbedUrl && (
        <Modal isOpen={modal.isOpen} onClose={modal.onClose}>
          <ModalOverlay />
          <ModalContent width="90vw" maxW="4xl" bg="black">
            <Box overflow="hidden">
              <AspectRatio ratio={16 / 9}>
                <iframe
                  title={title}
                  src={videoEmbedUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
            <ModalCloseButton pos="absolute" color="white" top="1" right="1" />
          </ModalContent>
        </Modal>
      )}
    </Stack>
  );
};
