/* Basic HTML range input styled to be invisible to just utilise its mouse interactions */

import type { FC, InputHTMLAttributes } from 'react';
import './InvisibleSlider.scss';

type InvisibleSliderProps = InputHTMLAttributes<HTMLInputElement>;

export const InvisibleSlider: FC<InvisibleSliderProps> = ({ type, className, ...props }) => (
  <input type="range" className={`invisible-slider${className ? ` ${className}` : ''}`} {...props} />
);
