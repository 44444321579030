import { Alert, AlertDescription, AlertIcon, AlertTitle, Heading } from '@chakra-ui/react';

import type { AlertStatus } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';

type Props = {
  status?: AlertStatus;
  title: string;
  description: ReactNode;
  size?: 'sm' | 'md';
};

export const VideoAlert: FC<Props> = ({ status = 'error', title, description, size = 'md' }) => {
  const iconColor = (() => {
    switch (status) {
      case 'error':
        return 'red.500';
      default:
        return undefined;
    }
  })();
  return (
    <Alert
      status={status}
      variant="unstyled"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      w="full"
      h="full"
    >
      {size === 'md' && <AlertIcon boxSize="43px" mx={0} color={iconColor} />}
      <AlertTitle mt={size === 'md' ? 6 : 0} mb={size === 'md' ? 4 : 2} mx={0}>
        <Heading size="md">{title}</Heading>
      </AlertTitle>
      <AlertDescription lineHeight={size === 'md' ? 'tall' : 'short'} fontSize={size === 'md' ? 'md' : 'sm'}>
        {description}
      </AlertDescription>
    </Alert>
  );
};
