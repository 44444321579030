import type { KeyboardEvent } from 'react';

export const onlyEnterPressed = (e: KeyboardEvent<HTMLTextAreaElement>): boolean => {
  return e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey && !e.metaKey;
};

export const ctrlPeriodPressed = (e: KeyboardEvent<HTMLTextAreaElement>): boolean => {
  return e.ctrlKey && e.code === 'Period';
};

export const altPeriodPressed = (e: KeyboardEvent<HTMLTextAreaElement>): boolean => {
  return e.altKey && e.code === 'Period';
};

export const escapePressed = (e: KeyboardEvent<HTMLTextAreaElement>): boolean => {
  return e.key === 'Escape';
};
