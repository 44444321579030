import { Box, Flex, Image } from '@chakra-ui/react';

import { PlayIcon } from '../Icons';

import type { PlayerUiSize } from './types';
import type { BoxProps } from '@chakra-ui/react';

type Props = {
  src: string;
  onClick: () => void;
  iconSize?: PlayerUiSize;
};

type SizeValues = { boxW: BoxProps['width']; boxH: BoxProps['height']; icon: BoxProps['boxSize'] };

const SIZE_CONFIG: Record<PlayerUiSize, SizeValues> = {
  sm: { boxW: '10', boxH: '8', icon: '4' },
  md: { boxW: '12', boxH: '10', icon: '5' },
};

export const VideoPoster = ({ src, iconSize = 'sm', onClick }: Props) => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      onClick={onClick}
      role="button"
      cursor="pointer"
      zIndex={1}
      borderRadius="md"
      background="black"
    >
      <Image src={src} objectFit="contain" width="100%" height="100%" alt="Video placeholder image - click to play" />
      <Flex top={0} left={0} right={0} bottom={0} justify="center" align="center" pos="absolute" role="group">
        <Box
          backgroundColor="blackAlpha.400"
          w={SIZE_CONFIG[iconSize].boxW}
          h={SIZE_CONFIG[iconSize].boxH}
          borderRadius="md"
          transition="background-color 0.2s"
          _groupHover={{ backgroundColor: 'blackAlpha.500' }}
        >
          <PlayIcon
            color="white"
            pos="absolute"
            left="50%"
            top="50%"
            boxSize={SIZE_CONFIG[iconSize].icon}
            transform="translate(-50%,-50%)"
          />
        </Box>
      </Flex>
    </Box>
  );
};
