// Includes the mute/unmute button and volume slider

import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import { VideoVolumeButton } from './VideoVolumeButton';

import type { PlayerUiSize } from '../types';
import type { SliderProps } from '@chakra-ui/react';
import type { RefObject } from 'react';

type Props = {
  isDisabled: boolean;
  defaultVolume: number;
  setVolume: (volume: number) => void;
  size?: PlayerUiSize;
  wrapperRef: RefObject<HTMLDivElement>;
  isFullscreen: boolean;
};

const VideoVolumeControl = ({ isDisabled, defaultVolume, setVolume, size, wrapperRef, isFullscreen }: Props) => {
  // Volume control states - separate states for volume slider & mute so that the user can unmute and return to previous volume
  const [volumeSlider, setVolumeSlider] = useState(defaultVolume * 100);
  const [videoMute, setVideoMute] = useState(false);

  const volumeSliderDefaultProps: SliderProps = {
    'aria-label': 'Volume slider',
    min: 0,
    max: 100,
    size: 'md',
    w: '20',
    colorScheme: 'gray',
  };

  // actual volume to pass to the player
  const videoVolume = useMemo(() => (videoMute ? 0 : (volumeSlider / 100) ** 2), [videoMute, volumeSlider]);
  useEffect(() => {
    if (setVolume) {
      setVolume(videoVolume);
    }
  }, [setVolume, videoVolume]);

  const muteButtonOnClick = () => {
    if (videoMute && volumeSlider === 0) {
      setVolumeSlider(100);
    }
    setVideoMute(!videoMute);
  };

  if (size === 'sm') {
    return (
      <VideoVolumeButton
        isFullscreen={isFullscreen}
        isDisabled={isDisabled}
        volume={videoVolume}
        onClick={muteButtonOnClick}
        wrapperRef={wrapperRef}
        size={size}
      />
    );
  }

  return (
    <Popover placement="right" trigger="hover" isLazy strategy="fixed">
      <PopoverTrigger>
        <Box>
          <VideoVolumeButton
            isFullscreen={isFullscreen}
            isDisabled={isDisabled}
            volume={videoVolume}
            onClick={muteButtonOnClick}
            wrapperRef={wrapperRef}
            size={size}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent border="none" background="none" padding="3" ml="-2" w="30" pr="6">
        {/*
        // Clicking the mute button should make the slider appear set to 0 without triggering an update to volumeSlider state so that volume can be reverted by unmuting
        */}
        {!isDisabled &&
          (videoMute ? (
            <Slider
              {...volumeSliderDefaultProps}
              value={0}
              isDisabled={isDisabled}
              onChange={value => {
                setVolumeSlider(value);
                setVideoMute(false);
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          ) : (
            <Slider
              {...volumeSliderDefaultProps}
              value={volumeSlider}
              onChange={setVolumeSlider}
              isDisabled={isDisabled}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          ))}
      </PopoverContent>
    </Popover>
  );
};

export { VideoVolumeControl };
